
import Vue from 'vue'
import http from './axios'
import '../assets/base.css'
import { Message } from 'element-ui';
Vue.prototype.$message = Message;

export const apiGet = (url, params, header) => {
    return new Promise((resolve, reject) => {
        http.get(url, {
          params: params,
          header
        }).then((response) => {
            resolve(response)
        }, (response) => {
            console.log(response)
            reject(response)
            let st = '';
            response.response && response.response.status ? st = response.response.status : st = JSON.stringify(response);
            // Vue.prototype.$ektAlert('请求超时，请检查网络', 'error', 50000, st)
            console.log(st);
            Vue.prototype.$message({
              message: st,
              type: 'error',
              customClass: 'global-message-dialog'
            })
        })
    })
}
export const apiPost = (url, data) => {
    return new Promise((resolve, reject) => {
        http.post(url, data).then((response) => {
            resolve(response)
        }).catch((response) => {
            reject(response)
            let st = '';
            response.response && response.response.status ? st = response.response.status : st = JSON.stringify(response);
            // Vue.prototype.$ektAlert('请求超时，请检查网络', 'error', 50000, st)
            console.log(response, st);
            Vue.prototype.$message({
              message: st,
              type: 'error',
              customClass: 'global-message-dialog'
            })
        })
    })
}