import { baseUrl as baseURL } from '@/service/env'

export const FUNC_LIST = [
  { title: '易支付', prop: 'easy-pay' },
  // { title: '权限管理', prop: 'project-manage' },
  { title: '帮助中心', prop: 'help-center' },
]

export const SUB_TAB_LIS = [
  {
    label: '全部',
    props: ''
  },
  {
    label: '电脑端',
    props: 'pc'
  },
  {
    label: '手机APP',
    props: 'app'
  },
  {
    label: '微信及小程序',
    props: 'wechat'  // wxgzh
  }
]

export const USER_MENU = [
  {
    label: '个人主页',
    prop: 'profile',
    icon: 'icon-a-zu24519',
    is_divided: false
  },
  {
    label: '皮肤设置',
    prop: 'theme',
    icon: 'icon-a-zu24520',
    is_divided: false
  },
  {
    label: '支付记录',
    prop: 'account-center',
    icon: 'icon-a-zu24518',
    is_divided: false
  },
  {
    label: '重置状态',
    prop: 'reset',
    is_divided: true
  },
  {
    label: '退出登录',
    prop: 'logout',
    icon: 'icon-a-zu24521',
    is_divided: true
  },
]

// 侧栏进入指定微应用时重置滚动条
export const RESET_SCROLLBAR_LIS = [
  '/app-list',
  '/billing-plan',
]

// 路由符合则不会显示menu
const ROUTE_HIDE_LIS = [
  '/profile', 
  '/shopping-cart', 
  '/billing-plan',
  '/login',
  '/cockpit',
]
export const ROUTE_LIS = ROUTE_HIDE_LIS.map(v => (`${baseURL}${v}`));

// 游客模式的menu
export const TOURIST_LIS = [`${baseURL}/app-list`]

export const getIconLis = ({
  showShoppingCart,
  toggleCustomerService,
  showSeniorMember
}) => (
  [
    {
      icon: 'icon-gwc1',
      title: "购物车",
      type: "image",
      canShowBadge: true,
      badgeValue: 0,
      click: showShoppingCart
    },
    {
      icon: 'icon-kf',
      title: "客服",
      type: "image",
      click: toggleCustomerService
    },
    {
      src: '',
      title: "高级会员",
      type: "status",
      click: showSeniorMember
    }
  ]
)

export const EMOJI_LIS = [
  // { name: '笑脸', posi: '-72px 0' },
  { name: '笑嘻嘻', posi: '0px 0' },
  // { name: '微笑', posi: '-1656px 0' },
  // { name: '萌萌哒', posi: '-2832px 0' },
  { name: '眨眼', posi: '-192px 0' },
  // { name: '色迷迷', posi: '-1728px 0' },
  { name: '飞吻', posi: '-360px 0' },
  // { name: '么么哒', posi: '-1800px 0' },
  // { name: '调皮', posi: '-1824px 0' },
  // { name: '吐舌头', posi: '-1848px 0' },
  // { name: '脸红', posi: '-600px 0' },
  { name: '露齿而笑', posi: '-24px 0' },
  { name: '沉思', posi: '-288px 0' },
  // { name: '满意', posi: '-1704px 0' },
  { name: '不满', posi: '-240px 0' },
  // { name: '苦瓜脸', posi: '-1896px 0' },
  // { name: '失望', posi: '-1872px 0' },
  // { name: '无助', posi: '-432px 0' },
  { name: '伤心', posi: '-408px 0' },
  { name: '喜极而泣', posi: '-48px 0' },
  // { name: '哭泣', posi: '-1992px 0' },
  // { name: '困', posi: '-1920px 0' },
  // { name: '冷汗', posi: '-528px 0' },
  { name: '尴尬', posi: '-96px 0' },
  { name: '汗', posi: '-264px 0' },
  // { name: '抓狂', posi: '-1944px 0' },
  // { name: '疲惫', posi: '-504px 0' },
  // { name: '可怕', posi: '-480px 0' },
  // { name: '尖叫', posi: '-552px 0' },
  { name: '生气', posi: '-384px 0' },
  // { name: '怒气冲冲', posi: '-456px 0' },
  { name: '蒙羞', posi: '-336px 0' },
  { name: '大笑', posi: '-120px 0' },
  // { name: '馋', posi: '-1680px 0' },
  // { name: '口罩', posi: '-696px 0' },
  // { name: '墨镜', posi: '-1752px 0' },
  // { name: '睡眠', posi: '-624px 0' },
  // { name: '头晕眼花', posi: '-648px 0' },
  // { name: '震惊', posi: '-576px 0' },
  { name: '小恶魔', posi: '-168px 0' },
  // { name: '恶魔', posi: '-1536px 0' },
  // { name: '惊呆', posi: '-2016px 0' },
  // { name: '扮鬼脸', posi: '-1968px 0px' },
  { name: '困惑', posi: '-312px 0' },
  // { name: '无口', posi: '-672px 0' },
  { name: '天使光环', posi: '-144px 0'  },
  // { name: '傻笑', posi: '-1776px 0' },
  { name: '面无表情', posi: '-216px 0'  },
  // { name: '不看', posi: '-2568px 0' },
  // { name: '不听', posi: '-2592px 0' },
  // { name: '闭嘴', posi: '-2040px 0' },
  // { name: '外星人', posi: '-1512px 0' },
  // { name: '便便', posi: '-984px 0' },
  // { name: '心碎', posi: '-2496px 0' },
  // {  name: '火', posi: '-2520px 0' },
  // { name: '愤怒', posi: '-912px 0' },
  // { name: 'ZZZ', posi: '-960px 0' },
  // { name: '禁止', posi: '-1128px 0' },
  // { name: '星星', posi: '-2640px 0' },
  // { name: '闪电', posi: '-2712px 0' },
  // { name: '弯月', posi: '-2088px 0' },
  // { name: '晴朗', posi: '-2952px 0' },
  // { name: '多云', posi: '-2784px 0' },
  // { name: '云彩', posi: '-2976px 0' },
  // { name: '雪花', posi: '-3048px 0' },
  // { name: '雨伞', posi: '-3000px 0' },
  // { name: '雪人', posi: '-2760px 0' },
  // {  name: '赞', posi: '-1344px 0' },
  // { name: '喝倒彩', posi: '-1368px 0' },
  // { name: '握手', posi: '-3072px 0' },
  // { name: '没问题', posi: '-1320px 0' },
  // { name: '举起拳头', posi: '-2856px 0' },
  // { name: '击拳', posi: '-1296px 0' },
  // {  name: '耶', posi: '-2904px 0' },
  // { name: '举手', posi: '-2880px 0' },
  // { name: '祈祷', posi: '-2064px 0' },
  // { name: '第一', posi: '-2808px 0' },
  // { name: '鼓掌', posi: '-1392px 0' },
  // { name: '肌肉', posi: '-1008px 0' },
  // { name: '家庭', posi: '-1416px 0' },
  // { name: '情侣', posi: '-1440px 0' },
  // { name: '宝贝天使', posi: '-1488px 0' },
  // {  name: '马', posi: '-2376px 0' },
  // {  name: '狗', posi: '-2400px 0' },
  // {  name: '猪', posi: '-2424px 0' },
  // {  name: '鬼', posi: '-1464px 0' },
  // { name: '玫瑰', posi: '-2136px 0' },
  // { name: '向日葵', posi: '-1200px 0' },
  // { name: '松树', posi: '-2112px 0' },
  // { name: '圣诞树', posi: '-2304px 0' },
  // { name: '礼物', posi: '-2256px 0' },
  // { name: '聚会礼花', posi: '-2328px 0' },
  // { name: '钱袋', posi: '-1032px 0' },
  // { name: '生日蛋糕', posi: '-2280px 0' },
  // { name: 'BBQ', posi: '-2184px 0' },
  // { name: '米饭', posi: '-1224px 0' },
  // { name: '冰淇淋', posi: '-2208px 0' },
  // { name: '巧克力', posi: '-1248px 0' },
  // { name: '西瓜', posi: '-2160px 0' },
  // { name: '红酒', posi: '-2232px 0' },
  // { name: '干杯', posi: '-1272px 0' },
  // { name: '咖啡', posi: '-3024px 0' },
  // { name: '篮球', posi: '-792px 0' },
  // { name: '足球', posi: '-2736px 0' },
  // { name: '单板滑雪', posi: '-816px 0' },
  // { name: '麦克风', posi: '-720px 0' },
  // { name: '音乐', posi: '-768px 0' },
  // { name: '骰子', posi: '-744px 0' },
  // { name: '麻将', posi: '-864px 0' },
  // { name: '王冠', posi: '-2448px 0' },
  // { name: '口红', posi: '-2472px 0' },
  // { name: '吻', posi: '-1584px 0' },
  // { name: '戒指', posi: '-1608px 0' },
  // { name: '书籍', posi: '-1056px 0' },
  // { name: '毕业帽', posi: '-2352px 0' },
  // { name: '铅笔', posi: '-2928px 0' },
  // { name: '房子', posi: '-840px 0' },
  // { name: '淋浴', posi: '-1152px 0' },
  // { name: '灯泡', posi: '-888px 0' },
  // { name: '电话听筒', posi: '-1080px 0' },
  // { name: '扩音器', posi: '-1104px 0' },
  // { name: '表', posi: '-2544px 0' },
  // { name: '闹钟', posi: '-2664px 0' },
  // { name: '沙漏', posi: '-2688px 0' },
  // { name: '炸弹', posi: '-936px 0' },
  // { name: '手枪', posi: '-1632px 0' },
  // { name: '药', posi: '-1560px 0' },
  // { name: '火箭', posi: '-2616px 0' },
  // { name: '地球', posi: '-1176px 0' },
]
