export const PLATFORM_ICON = {
  'app': 'app',
  'bg': 'bg',
  'say': 'say',
  '支持HIS对接': 'his',
  '微信公众号': 'wxgzh',  
  '微信小程序': 'xcx',
  '支持第三方对接': 'dsf',
}

export const MODULE_ICON = {
  'pc': 'icon-a-zujian962',
  'app': 'icon-a-zujian922',
  'wechat': 'icon-a-zujian932',
  'wxgzh': 'icon-a-zujian942'
}