import utils from '@/utils/util'
import store from '@/store/index'
import router from './router'
import Vue from 'vue'
import Cookies from 'js-cookie'
import { initGlobalState, loadMicroApp } from 'qiankun'
import { INNER_MICROAPP_LIS as inner } from '../../micro-app.json'
import { outer, dialog } from '../../data.json'
const isProduction = process.env.NODE_ENV === 'production'
const entryBaseUrl = '/html/child'
const routeBaseUrl = isProduction ? '/html' : ''
const ipAddress = '//192.168.2.140' // 192.168.2.174

/**
 * name: 微应用名称 - 具有唯一性
 * entry: 微应用入口 - 通过该地址加载微应用
 * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
 * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
 */

// 主微应用通信必须初始化属性才能监听
let initState = Vue.observable({
  shouldUpdateInfo: false,
  shouldUpdateModule: false,
  currAsideMenu: [],
  currView: ''
})
const actions = initGlobalState(initState)
console.log(actions,store,'actions');
actions.onGlobalStateChange((state, prev) => {
  console.log('onGlobalStateChange..',state, prev);
  if(state.shouldUpdateInfo){
    store.dispatch('getUserInfo')
    actions.setGlobalState({
      shouldUpdateInfo: false
    })
  }
  if(state.shouldUpdateModule){
    store.dispatch('getLis')
    actions.setGlobalState({
      shouldUpdateModule: false
    })
  }
  store.commit('saveCurrAsideMenu', {
    asideMenu: state.currAsideMenu,
    currView: state.currView
  })
})
actions.setGlobalState(initState);

// 定义一个获取state的方法下发到子应用
actions.getGlobalState = (key) => {
  // 有key，表示取globalState下的某个子级对象
  // 无key，表示取全部
  console.log(key);
  return key ? initState[key] : initState
}
Vue.prototype.$actions = actions;

let dialogApp = {}
Object.keys(dialog).forEach(key => {
  const v = dialog[key]
  dialogApp[key] = {
    name: v.name,
    entry: isProduction ? v.online_url : `${ipAddress}:${v.port}${v.dev_url}`,
    container: `#${v.name}-dialog-container`,  // e.g. id="#download-dialog-container"
    props: {}
  }
})

const commonApp = {
  'module-card-list': {
    name: "module-card-list",
    entry: isProduction ? `${entryBaseUrl}/module-card-list/` : `${ipAddress}:6100`,
    container: "#module-card-list-container",
    props: {
      title: '卡片列表',
      router,
      store,
      loadMicroApp,
      microApp: dialogApp,
      routerBase: `${routeBaseUrl}/module-card-list`
    }
  }
}

// 接入的外部微应用
const outerMicroApp = outer.map(v => {
  return {
    name: v.name,
    entry: isProduction ? v.online_url : `${ipAddress}:${v.port}${v.dev_url}`,
    container: "#subapp-container",
    activeRule: `${routeBaseUrl}/${v.name}`,
    title: v.title,
    props: {
      router,
      store,
      routerBase: `${routeBaseUrl}/${v.name}${v.name === 'bg' ? '' : '/'}`,  // 如果是bg这里最后不要加/
      session: Cookies.get('session'),  // bg可以不传，bg有问题则要做过滤
      env: Cookies.get('env'),  // bg可以不传，bg有问题则要做过滤
    }
  }
})

// 内部微应用。设定：主应用会默认跳转的子应用。像小程序一样，谁放第一位
const innerMicroApp = inner.map(v => {
  const onlineUrl = `${entryBaseUrl}/${v.name}${v.name !== 'app-list' ? '/' : ''}`  // 只有app-list需要在最后加/
  const devUrl = `${ipAddress}:${v.port}${v.dev_url}`
  return {
    name: v.name,
    entry: isProduction ? onlineUrl : devUrl,   // 能单独访问的实际的子应用路径（就是服务器上存放的子应用路径） 生产环境是根据目录放置来定
    container: "#subapp-container",  // 子应用所挂载的div节点
    activeRule: `${routeBaseUrl}/${v.name}`,      // 主应用下自定义子应用路由前缀
    title: v.title,  // 页面标题
    props: {
      // 基础项
      router,
      store,
      routerBase: `${routeBaseUrl}/${v.name}/`, // 下发路由给子应用，子应用根据该值去定义qiankun环境下的路由。注：routerBase最后有/，区别于activeRule
      // 额外项
      loadMicroApp,   // 需要手动加载微应用就需要，如app-list、cleaning、maintain-system
      microApp: commonApp,  // 同上
      getGlobalState: actions.getGlobalState,   // 只有app-list需要，如有影响则改为按需传参
      title: v.title,  // 只有project-manage和help-center需要
      utils,  // 只有project-manage和help-center需要
    }
  }
})

const microApp = [  // 设定：主应用会默认跳转的子应用。像小程序一样，谁放第一位
  ...innerMicroApp,
  ...outerMicroApp
]
export default microApp