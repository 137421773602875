<template>
  <div class="ekt-logo-wrapper" @click="handleGoBack">
    <span class="ekt-logo">医键通</span>
    <p>云平台</p>
  </div>
</template>

<script>
export default {
  methods: {
    handleGoBack(){
      this.$emit('handle-go-back')
    }
  },
}
</script>

<style lang="less">
@import url('./index.less');
</style>