import Vue from 'vue'
import Vuex from 'vuex'
import { PLATFORM_ICON } from '@common/constants'
import { MODULE_LIS } from '../../../module-lis.json'
import api from '../service/api';
import { INNER_MICROAPP_LIS } from '../../../micro-app.json'
import { isProd } from '@/service/env'
const routeBaseUrl = isProd ? 'html/' : ''
let APP_MENU_NAME = {}
INNER_MICROAPP_LIS.forEach(v => {
  APP_MENU_NAME[v.title] = v.name
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currRoute: '', // 记录当前路由，用于高亮menu
    currHash: '',
    userInfo: {
      name: '',
    },
    canShowAsideMenu: true,
    isCollapse: false,
    cartTotal: 0,
    isInMicroApp: false,  // 显隐微应用的asidebar
    currDialogName: 'login',
    microAppMenu: [],
    currMenuActive: '', // 用于高亮侧栏的图标
    chatLis: [],
    currIntroName: '',  // 当前查看的系统名称。用于“所有应用”和“系统介绍”微应用之间通信
    allAppList: [],
    filterValue: '',
    currSelectCartFix: '',
    appMenu: [],
    cdnPath: {},
    currAsideMenu: []
  },
  mutations: {
    saveCurrAsideMenu(state, data){
      state.microAppMenu = {
        ...state.microAppMenu,
        ...data
      }
    },
    saveCdn(state, data){
      state.cdnPath = data;
    },
    saveAppMenu(state, data){
      state.appMenu = data.map(v => {
        return {
          name: v.name,
          vip: v.vip,
          prop: APP_MENU_NAME[v.name]
        }
      })
    },
    toggleCustomerService(state, fromCartFix = false){
      if(fromCartFix){
        state.currSelectCartFix = '客服'
      }else{
        if(state.currSelectCartFix === '客服'){
          state.currSelectCartFix = '';
        }else{
          state.currSelectCartFix = '客服'
        }
      }
    },
    saveFilterValue(state, data){
      state.filterValue = data;
    },
    saveAllAppLis(state, data){
      const { is_gm, vip } = state.userInfo;

      state.allAppList = data.map(v => {
        v.acls = v.acls.map((v2) => {
          let can_enter = false;
          if(is_gm === 1){
            // 如果是邀请人,需要他的vip >= 功能模块vip 而且 用户的vip >= 功能所属系统模块的vip
            can_enter = vip >= v2.vip && vip >= v.vip;
          }else if(is_gm === 0){
            // 如果是被邀请人,需要模块vip为0但is_buy为1
            can_enter = vip === 0 && v2.is_buy === 1;
          }
          return {
            ...v2,
            platformIcon: PLATFORM_ICON[v2.platform],
            canEnter: can_enter,
            statusTxtColor: !v2.vip ? (!v2.is_buy ? (!v2.no_cart ? '#FFB300' : '#207BFA') : '#207BFA') : '',
            statusTxt: !v2.vip ? (!v2.is_buy ? (!v2.no_cart ? '加入购物车' : '沟通需求') : '点击进入') : ''
          };
        });
        v.pro = v.acls.filter(v => !v.vip)
        return {
          ...v,
          activeName: 'pc'
        };
      }).filter(v => v.acls.length > 0);
      console.log('main---allAppList', state.allAppList);
    },
    saveCurrIntroName(state, data){
      state.currIntroName = data;
    },
    saveChatLis(state, {data,last_id}){
      if(!last_id){
        state.chatLis = data
      }else{
        data.forEach(v => {
          if(v.chat_id.indexOf('s-') === 0){
            Promise.resolve().then(this.$nextTick).then(() => {
              Vue.prototype.$notify({
                title: `您有1条新消息`,
                message: `<p>${v.msg.msg}</p>`,
                dangerouslyUseHTMLString: true,
              });
            });
          }
        })
        state.chatLis = [...state.chatLis, ...data];
      }
    },
    setMicroAppMenu(state, path = '') { // e.g. path = '/account-center'
      const menu = MODULE_LIS.find(v => `/${routeBaseUrl}${v.name}` === path)
      if(menu){
        state.microAppMenu = menu;
      }
    },
    toggleMicroAppStatus(state, data = false){
      state.isInMicroApp = data;
    },
    toggleLoginRegisterName(state, data){
      state.currDialogName = data || 'login';
    },
    saveCartTotal(state, data){
      state.cartTotal = data;
    },
    toggleCollapse(state){
      state.isCollapse = !state.isCollapse;
    },
    toggleAsideMenu(state){
      state.canShowAsideMenu = !state.canShowAsideMenu;
    },
    saveUserInfo(state, data){
      state.userInfo = data;
    },
    saveCurrRoute(state, data){
      state.currRoute = data;
    },
    saveCurrHash(state, data){
      state.currHash = data;
    }
  },
  actions: {
    async getCdnPath({ commit }){
      const { data } = await api.cdnUrl();
      commit('saveCdn', data)
    },
    async getAclData(){
      const res = await api.getAclUrl();
      return res;
    },
    async getLis({ commit }){
      const res = await api.getLisUrl();
      commit('saveAllAppLis', res.data.data)
      commit('saveAppMenu', res.data.data)
      console.log(res)
    },
    async resetAccountStatus(){  // 重置购买状态
      const res = await api.resetAclUrl()
      return res.data.success;
    },
    async getChatLis({ commit }, data = 0){
      const res = await api.getChatLisUrl(data);
      commit('saveChatLis', {data:res.data.data, last_id:data})
      // Vue.prototype.$message({
      //   message: res.data.success ? '获取成功!' : res.data.msg,
      //   type: res.data.success ? 'success' : 'error'
      // })
      return res.data.success;
    },
    async sendMsg({ commit }, data){
      console.log(commit, data);
      const res = await api.postChatMsgUrl(data);
      Vue.prototype.$message({
        message: res.data.success ? '发送成功!' : res.data.msg,
        type: res.data.success ? 'success' : 'error'
      })
      return res.data.success;
    },
    async postAddCartItem({ commit }, data){
      console.log('postAddCartItem', commit, data);
      const res = await api.postCartAddUrl(data);
      Vue.prototype.$message({
        message: res.data.success ? '添加到购物车成功!' : res.data.msg,
        type: res.data.success ? 'success' : 'error'
      })
      return res.data.success;
    },
    async getCartList({ commit }){
      const res = await api.postCartListUrl();
      if(res.data.success){
        console.log('saveCartTotal', res.data.data);
        commit('saveCartTotal', res.data.data.lis.length)
      }
      return res.data.success
    },
    async getUserInfo({ commit }){
      const res = await api.getUserInfoUrl();
      if(res.data.data) {
        commit('saveUserInfo', res.data.data)
        Vue.prototype.$Cookies.set('platform_name', res.data.data.name)
      }
      return res.data.success;
    },
    async logout({ commit }){
      const res = await api.logoutUrl();
      console.log(res, commit)
      commit('saveUserInfo', {
        name: ''
      })
      return res.data.success;
    }
  },
  modules: {
  }
})
