import Vue from "vue";
import Cookies from 'js-cookie';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { registerMicroApps, setDefaultMountApp, addGlobalUncaughtErrorHandler, start } from "qiankun";
import './assets/font/aegoole-icon.css'
import "@/libs/element-ui";
import microApp from "./micro-app";
import { baseUrl } from '@/service/env';
import { outer } from '../../data.json'

const BASE_TITLE = '医键通云平台';
document.title = BASE_TITLE;

Vue.prototype.$Cookies = Cookies;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

registerMicroApps(microApp, {
  beforeLoad: [
    app => {
      console.log("main [LifeCycle] before load %c%s", "color: green;", app.name);
      document.title = `${BASE_TITLE}_${app.title}`
    }
  ],
  beforeMount: [
    app => {
      store.commit('saveCurrRoute', app.name)
      store.dispatch('getCartList')
      store.dispatch('getLis')
      console.log("main [LifeCycle] before mount %c%s", "color: green;", app.name);
      console.log(Cookies.get('platform_name'), store.state.userInfo);
    }
  ],
  afterUnmount: [
    app => {
      console.log("main [LifeCycle] after unmount %c%s", "color: green;", app.name);
    }
  ]
});

addGlobalUncaughtErrorHandler((event) => {
  try {
    const { message } = event;
    console.log('GlobalUncaughtErrorHandler: ', event, message);
    if(message){
      const name = message.split("Uncaught TypeError: application '").join('').split("' died in")[0]
      console.log('name:', name);
      const obj = microApp.find(v => v.name === name)
      if(obj){
        Vue.prototype.$message.error(`微应用：${obj.title} 加载失败，检查应用是否已运行`);
      }
    }
  } catch (error) {
    console.log(error);
  }
});

// 默认进入所有应用（后续可能是广告页）
if(location.pathname === baseUrl+'/'){
  setDefaultMountApp(microApp[9].activeRule) // 暂时默认进入易支付
}

start({
  // 给指定的微应用 entry 开启跨域请求
  fetch(url, ...args) {
    // 注意：这里是请求哪个页面就要完整的写哪个html
    /**
     * 常见错误1：填写了//say.eai9.com/ 导致请求到登录页
     * 常见错误2：加了https前缀也是无效。例如：https://say.eai9.com/auth/customdeliveryaddress.html
     * 常见错误3：
     */
    const outerUrlLis = outer.map(v => v.online_url)
    if(outerUrlLis.includes(url)){
      console.log('请求...'+url);
      return window.fetch(url, {
        ...args,
        mode: 'cors',
        credentials: 'include',
      });
    }
    
    return window.fetch(url, ...args).catch(() => {
      return {
        async text(){
          return '加载失败，检查应用是否已运行';
        }
      }
    });
  },
  // sandbox: { experimentalStyleIsolation: true } // 沙箱模式，样式隔离。不要打开，坑。部分微应用挂载到主应用body的组件将会丢失样式
});