<template>
  <button :class="[getClass, isDisabled?' is-disabled':'']" :disabled="isDisabled?true:false" :native-type="nativeType" @click="handleClick">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props:{
    type: {
      type: [String, Array],
      default: 'default'
    },
    isRadius: {
      type: Boolean,
      default: false
    },
    isCircle: {
      type: Boolean,
      default: false
    },
    isShadow: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    nativeType: {
      type: String,
      default: 'button'
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getClass(){
      let str = 'ekt-button'
      if(['primary', 'default', 'normal', 'info', 'enter', 'buy', 'open','join-cart','talk','error'].includes(this.type)) {
        str += ` ekt-button--${this.type}`
      }
      if(this.isCircle) {
        str += ` ekt-button--circle`
      }
      if(this.isRadius) {
        str += ` ekt-button--radius`
      }
      if(this.isShadow) {
        if(this.type === 'buy') str += ` ekt-button--buy-shadow`
        if(this.type === 'open') str += ` ekt-button--open-shadow`
      }
      return `${str} ${this.customClass}`
    }
  },
  methods: {
    handleClick(event){
      this.$emit('click', event)
    }
  },
}
</script>

<style lang="less">
@import url('./index.less');
</style>