<template>
  <el-menu
    router
    mode="vertical"
    :collapse-transition="true"
    :default-openeds="app"
    :default-active="activeIndex"
    :collapse="isCollapse"
    @select="handleSelect"
    @open="handleOpen"
    @close="handleClose"
    class="el-menu-vertical-demo"
  >
    
    <template v-if="isInMicroApp">

      <el-menu-item
        :class="['micro-app-menu-item', 
          {'active': item.prop === currHash }
        ]"
        :ttt="currHash"
        @click="toggleJump(item)"
        v-for="item in microAppMenu.asideMenu"
        :key="item.prop"
        :title="`${baseUrl}/${microAppMenu.name}${item.prop !== '' ? `/${item.prop}` : ''}`"
      >
        <!-- 加div防止展开/收起时icon抖动 -->
        <div class="icon">
          <i :class="[`aegoole-icon ${item.icon}`]"></i>
        </div>
        <div class="title">
          <p slot="title">{{item.label}}</p>
        </div>
      </el-menu-item>
      
    </template>

    <template v-if="!isInMicroApp">

      <!-- <el-menu-item :index="`${baseUrl}/app-list`" key="app-list">
        <div>
          <i :class="[`ekt-icon ekt-icon-app-list`, {'theme': activeIndex === `${baseUrl}/app-list` || canShowTheme}]"></i>
        </div>
        <div>
          <span slot="title">所有应用</span>
        </div>
      </el-menu-item> -->

      <template v-if="userInfo.name">
        
        <el-divider></el-divider>
        
        <el-menu-item
          :index="`${baseUrl}/${item.prop}`"
          v-for="item in funcList"
          :key="item.prop"
          :title="item.title"
        >
          <div>
            <i :class="[`ekt-icon ekt-icon-${item.prop}`, {'theme': activeIndex === `${baseUrl}/${item.prop}` || canShowTheme}]"></i>
          </div>
          <div>
            <span slot="title">{{item.title}}</span>
          </div>
        </el-menu-item>
      </template>

      <el-divider></el-divider>
      
      <el-menu-item
        :index="`${baseUrl}/${item.prop}`"
        v-for="item in getShowAppList"
        :key="item.prop"
        :title="item.title">
        <!-- 加div防止展开/收起时icon抖动 -->
        <div>
          <i :class="[`ekt-icon ekt-icon-${item.prop}`, {'theme': activeIndex === `${baseUrl}/${item.prop}` || canShowTheme}]"></i>
        </div>
        <div>
          <span slot="title">{{item.name}}</span>
        </div>
      </el-menu-item>

    </template>
  </el-menu>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { FUNC_LIST, ROUTE_LIS } from '@/constants'
import { baseUrl as baseURL, isProd } from '@/service/env'
export default {
  props: {
    currActive: {
      type: String,
      default: ''
    },
    isCollapse: {
      type: Boolean
    }
  },
  data() {
    return {
        app: [`${baseURL}/app-list`],
        activeIndex: '',
        funcList: FUNC_LIST,
        canShowTheme: false,  // 肯定用store保存的
        currExpand: [],
        baseUrl: baseURL,
        currLis: []
    };
  },
  computed: {
    getShowAppList(){
      if(this.userInfo.vip){
        return this.appMenu.filter(v => v.vip <= this.userInfo.vip)
      }
      return []
    },
    ...mapState(['appMenu', 'userInfo', 'isInMicroApp', 'microAppMenu', 'canShowAsideMenu', 'currHash'])
  },
  watch: {
    currActive:{
      handler(){
        this.activeIndex = `${this.baseUrl}/${this.currActive}`
      },
      immediate: true
    },
    $route:{
      handler(){
        if(isProd){
          const PREFIX_NUM = 5; // 路由前缀 /html/
          if(this.$route.path.lastIndexOf('/') === PREFIX_NUM){
            // 属于非模块类别时，根据条件隐藏asideMenu
            if(ROUTE_LIS.includes(this.$route.path) && this.canShowAsideMenu){
              this.$emit('toggle-aside-menu')
            }
          }
        }else{
          const PREFIX_NUM = 0; // 路由前缀 /
          if(this.$route.path.lastIndexOf('/') === PREFIX_NUM){
            // 属于非模块类别时，根据条件隐藏asideMenu
            if(ROUTE_LIS.includes(this.$route.path) && this.canShowAsideMenu){
              this.$emit('toggle-aside-menu')
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    toggleJump(item){
      window.location.href = window.location.pathname + '#' + item.prop
      this.$actions.setGlobalState({
        location: `${window.location.href}`
      });
      this.saveCurrHash(item.prop)
      // 针对SAY很多应用都不采用这样直接跳的方式，而采用hash锚点
      // history.pushState('', '', `${this.baseUrl}/${this.microAppMenu.name}${item.prop !== '' ? `/${item.prop}` : ''}`)
    },
    handleClick(){
      this.activeIndex = `${this.baseUrl}/app-list`
    },
    handleSelect(index){
      this.activeIndex = index;
    },
    checkExpand(key){
      if(this.currExpand.includes(key)){
        const idx = this.currExpand.findIndex(v => v === key)
        this.currExpand.splice(idx,1)
      }else{
        this.currExpand.push(key)
      }
    },
    handleOpen(key) {
      this.activeIndex = key;
      this.checkExpand(key);
    },
    handleClose(key) {
      this.activeIndex = '';
      this.checkExpand(key);
    },
    ...mapMutations(['saveCurrHash'])
  },
};
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>