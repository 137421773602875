<template>
  <div class="ekt-header-wrapper">
    <!-- row的gutter=20 内部col划分span共24 -->
      <el-row :gutter="20" type="flex" align="middle" justify="space-between">
        <el-col :span="10" v-if="!isInMicroApp">
          <el-row type="flex" align="middle" justify="start">
            <template v-if="canSearch">
              <i :class="[`ekt-icon ekt-icon-${isCollapse ? 'unfold' : 'fold'}`]" @click="handleToggleMenu"></i>
              <div class="input-wrapper" v-if="isFilter">
                <el-input
                  placeholder="检索所有应用"
                  prefix-icon="el-icon-search"
                  v-model="inputValue" 
                  @change="handleFilter"
                ></el-input>
              </div>
            </template>
            <template v-else>
              <EktLogo v-on="$listeners"></EktLogo>
            </template>
          </el-row>
        </el-col>
        <el-col :span="4" v-else>
          <EktLogo v-on="$listeners"></EktLogo>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20" type="flex" align="middle" justify="end" v-if="userInfo.name">
            <div>
              <span class="hospital-txt">{{userInfo.company}}</span>
            </div>
            <el-row type="flex" align="middle" class="user-info">
              <el-avatar :size="36" :src="getPath" :title="cdnPath.defaultAvatar"></el-avatar>
              <img v-if="badge" class="badge-icon" :src="badge" alt="">
              <el-dropdown class="userinfo-dropdown" trigger="click">
                <div class="el-dropdown-link">
                  <span :title="userInfo.name" class="username-txt">{{userInfo.name}}</span>
                  <span class="icon-border">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                </div>
                <el-dropdown-menu  class="userinfo-dropdown-menu" slot="dropdown">
                  <el-dropdown-item v-for="item in userMenu" :key="item.prop" :divided="item.is_divided" @click.native="handleClick(item.prop)">
                    <div class="menu-item">
                      <i :class="[`aegoole-icon ${item.icon}`]"></i>
                      <span>{{item.label}}</span>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-row>
          </el-row>
          <el-row :gutter="20" type="flex" align="middle" justify="end" v-else>
            <div class="login-btn-wrapper">
              <!-- 自助注册关闭，改为去特殊控制里新增账号 -->
              <!-- <EktButton type="normal" :is-radius="true" @click="handleLogin('register')">注册</EktButton> -->
              <EktButton type="primary" :is-radius="true" @click="handleLogin('login')">登录</EktButton>
            </div>
          </el-row>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import EktButton from '@common/components/ekt-button'
import EktLogo from '@/components/ekt-logo'
import { USER_MENU } from '@/constants'
import { cdnUrl } from '@/service/env'
export default {
  inheritAttrs: false,
  props:{
    canSearch: {
      type: Boolean,
      default: true
    },
    isCollapse: {
      type: Boolean,
      default: false
    },
    isFilter: {
      type: Boolean,
      default: false
    },
    badge: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
        inputValue: '',
        userMenu: USER_MENU,
        currType: '',
        status: 0,
    }
  },
  computed:{
    getPath(){
      return `${cdnUrl}${this.cdnPath.defaultAvatar}`
    },
    ...mapState(['userInfo', 'isInMicroApp', 'cdnPath'])
  },
  components:{
    EktButton,
    EktLogo,
  },
  methods: {
    handleLogin(text){
      this.$emit('handle-login', text)
    },
    handleFilter(){
      this.$actions.setGlobalState({
        filterValue: this.inputValue
      });
      this.saveFilterValue(this.inputValue)
    },
    handleGoBack(){
      this.$emit('handle-go-back')
    },
    handleClick(type){
      this.currType = type;
      this.$emit('handle-click', type)
    },
    handleToggleMenu(){
      this.$emit('handle-toggle-menu')
    },
    ...mapMutations(['saveFilterValue'])
  },
}
</script>

<style lang="less">
@import url('./index.less');
</style>