<template>
  <div id="app" class="cloud-platform">
    <template v-if="!isInMicroApp">
      <el-container>
        <EktAside v-show="canShowAsideMenu" :width="isCollapse ? '' : '243px'">
          <router-link
            :to="`${baseUrl}/app-list`"
            :class="['ekt-logo-wrapper homepage', { theme: canShowTheme }]"
          >
            <span class="ekt-logo">医键通</span>
            <p :class="{ none: !canShowTitle }">云平台</p>
          </router-link>
          <EktScrollbar :height="'91%'">
            <AsideMenu
              :curr-active="currRoute"
              :isCollapse="isCollapse"
              @toggle-aside-menu="toggleAsideMenu"
            ></AsideMenu>
          </EktScrollbar>
        </EktAside>
        <el-container direction="vertical">
          <div class="fixed-header" v-if="!canGoLogin && (routePath === `${baseUrl}/cockpit`)">
             <el-header v-if="canShowHeader">
              <EktHeader
                :badge="badge"
                :can-search="canShowAsideMenu"
                :is-filter="$route.path.indexOf('app-list') !== -1"
                :is-collapse="isCollapse"
                @handle-login="handleLogin"
                @handle-click="handleClick"
                @handle-toggle-menu="toggleMenu"
                @handle-go-back="goHome"
              ></EktHeader>
             </el-header>
            <div class="toggle-btn" @click="toggleHeader">
              <i v-if="!canShowHeader" class="el-icon-arrow-up"></i>
              <i v-else class="el-icon-arrow-down"></i>
            </div>
          </div>
          <el-header v-if="!canGoLogin && (routePath !== `${baseUrl}/cockpit`)">
            <EktHeader
              :badge="badge"
              :can-search="canShowAsideMenu"
              :is-filter="$route.path.indexOf('app-list') !== -1"
              :is-collapse="isCollapse"
              @handle-login="handleLogin"
              @handle-click="handleClick"
              @handle-toggle-menu="toggleMenu"
              @handle-go-back="goHome"
            ></EktHeader>
          </el-header>
          <el-scrollbar ref="mainScrollbar">
            <el-main :style="{padding: canGoLogin || getPosi.includes(routePath) ? '0' : '20px'}">
              <CartAffix v-if="canShowAsideMenu" :data="iconLis" :curr-select="currSelectCartFix"></CartAffix>
              <!-- 子应用渲染区域，用于挂载子应用节点 -->
              <div id="subapp-container"></div>
            </el-main>
            <CopyRight v-if="canShowAsideMenu"></CopyRight>
          </el-scrollbar>
        </el-container>
      </el-container>
    </template>
    
    <template v-if="isInMicroApp">
      <el-container direction="vertical">
        <el-header v-if="!canGoLogin">
          <EktHeader
            :badge="badge"
            :can-search="canShowAsideMenu"
            :is-collapse="isCollapse"
            @handle-login="handleLogin"
            @handle-click="handleClick"
            @handle-go-back="toggleMicroApp"
          ></EktHeader>
        </el-header>
        <el-container direction="horizontal">
          <EktAside custom-class="micro-aside" :width="''">
            <EktScrollbar :height="'91%'">
              <AsideMenu
                :curr-active="currRoute"
                :isCollapse="true"
                @toggle-aside-menu="toggleAsideMenu"
              ></AsideMenu>
            </EktScrollbar>
          </EktAside>
          <el-container direction="horizontal">
            <EktScrollbar>
              <el-main :style="{padding: canGoLogin ? '0' : '20px'}">
                <CartAffix v-if="canShowAsideMenu" :data="iconLis" :curr-select="currSelectCartFix"></CartAffix>
                <!-- 子应用渲染区域，用于挂载子应用节点 -->
                <div id="subapp-container"></div>
              </el-main>
            </EktScrollbar>
          </el-container>
        </el-container>
      </el-container>
    </template>

    <!-- 主应用渲染区域，用于挂载主应用路由触发的组件 -->
    <!-- <router-view></router-view> -->

    <!-- 客服 -->
    <!-- <CustomerService :show="currSelectCartFix === iconLis[1].title" @handle-close="toggleCustomerService"></CustomerService> -->
  </div>
</template>

<script>
import CartAffix from "@/components/cart-affix";
import EktAside from "@/components/ekt-aside";
import AsideMenu from "@/components/aside-menu";
import EktScrollbar from "@/components/ekt-scrollbar";
import EktHeader from "@/components/ekt-header";
import CustomerService from '@/components/customer-service'
import CopyRight from '@/components/copyright'
import { baseUrl as baseURL, isProd } from "@/service/env";
import { mapActions, mapState, mapMutations } from "vuex";
import { ROUTE_LIS, TOURIST_LIS, RESET_SCROLLBAR_LIS } from '@/constants'
import { cdnUrl } from './service/env';
import { INNER_MICROAPP_LIS } from '../../micro-app.json'
export default {
  data() {
    return {
      canShowTheme: false, // 后续要用store保存
      canShowTitle: true,
      // iconLis: getIconLis({
      //   showShoppingCart: () => this.showShoppingCart(),
      //   toggleCustomerService: () => this.toggleCustomerService(),
      //   showSeniorMember: () => this.showSeniorMember(),
      // }),
      iconLis:[],
      baseUrl: baseURL,
      currSelect: '',
      canGoLogin: false,
      routePath: '',
      canShowHeader: false,
      badge: null
    };
  },
  components: {
    CartAffix,
    EktAside,
    AsideMenu,
    EktScrollbar,
    EktHeader,
    CustomerService,
    CopyRight,
  },
  created() {
    // console.log(this.currSelectCartFix === this.iconLis[1].title);
    console.log('main...created');
    this.getCdnPath();
    this.initData();
    this.getLis();
  },
  watch: {
    '$store.state.cdnPath'(){
      // this.iconLis[2].src = `${cdnUrl}${this.cdnPath.seniorMember}`
    },
    '$store.state.currIntroName'(){  // 查看系统介绍时重置滚动条
      if(this.$store.state.currIntroName){
        this.$refs.mainScrollbar.wrap.scrollTop = 0;
      }
    },
    cartTotal() {
      // this.iconLis[0].badgeValue = this.cartTotal;
    },
    $route:{
      handler(curr, prev){
        console.log(curr,'main 主应用监听路由', INNER_MICROAPP_LIS, prev);
        this.routePath = curr.path;
        // 侧栏进入所有应用时重置滚动条
        if(RESET_SCROLLBAR_LIS.includes(curr.path)){
          this.saveCurrIntroName('')
          if(this.$refs.mainScrollbar){
            this.$refs.mainScrollbar.wrap.scrollTop = 0;
          }
        }

        // 判断当前是否在指定menu名单中，是，则为微应用，需要隐藏侧栏，否，显示侧栏
        const inner = INNER_MICROAPP_LIS.map(v => (`${baseURL}/${v.name}`))
        const arr = [`${baseURL}/bedconfirm`, `${baseURL}/bedwashing`]
        if(!inner.includes(curr.path) && !arr.includes(curr.path)){
          console.log('curr.path', curr.path);
          this.toggleMicroAppStatus(true);
          this.setMicroAppMenu(curr.path);
        }else{
          this.toggleMicroAppStatus(false);
          if(!this.canShowAsideMenu) {
            this.toggleAsideMenu();
          }
        }

        // 如果是登录页做特殊处理
        if(curr.path.indexOf('login') !== -1){
          this.canGoLogin = true;
        }else if(this.canGoLogin){
          this.canGoLogin = false;
          if(!this.canShowAsideMenu) {
            this.toggleAsideMenu();
          }
        }

        // 用于监听微应用内部切换sidemenu时监听前进后退按钮（同时添加判断，避免点击目标微应用以外的微应用导致添加无效的hash）
        if(prev && curr.hash !== prev.hash && !inner.includes(`${curr.path}`)){
          console.log(inner, `${curr.path}`, curr, prev);
          this.$actions.setGlobalState({
            location: `${window.location.href}`
          });
          this.saveCurrHash(curr.hash)
        }

        // 有hash就保存
        if(window.location.hash){
          this.saveCurrHash(window.location.hash.slice(1))
        }
      },
      immediate: true
    }
  },
  computed: {
    getPosi(){
      return INNER_MICROAPP_LIS.map(v => `${this.baseUrl}/${v.name}`)
    },
    ...mapState([
      "currRoute",
      "userInfo",
      "canShowAsideMenu",
      "isCollapse",
      "cartTotal",
      "isInMicroApp",
      "currDialogName",
      "currIntroName",
      "currSelectCartFix",
      "cdnPath",
      "microAppLis"
    ])
  },
  methods: {
    async initData(){
      if(this.$Cookies.get('platform_name')){
        const res = await this.getUserInfo();
        this.judgeRoute();
        if(res){
          if(this.userInfo.name){
            this.getCartTotal();
            this.getAclLis();
            this.getBadgeIcon();
          }
        }else{
          this.$router.push(`${this.baseUrl}/app-list`)
        }
        this.handleAutoLogin();
      }
    },
    async getBadgeIcon(){
      if(this.userInfo.vip !== 0) {
        this.badge = `${cdnUrl}${this.cdnPath[`badge-${this.userInfo.vip}`]}`;
      }
    },
    getAclLis(){
      this.getAclData();
    },
    toggleHeader(){
      this.canShowHeader = !this.canShowHeader
    },
    async handleAutoLogin(){
      if(!this.$Cookies.get('session')){
        console.log('nothing');
        return;
      }
      await fetch(isProd?'https://say.eai9.com/autologin':'/say/api/autologin', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          session: this.$Cookies.get('session'),
          env: this.$Cookies.get('env'),
          platform: 'steam'
        })
      });
      await fetch(isProd?'https://bg.eai9.com/autologin':'/bg/autologin', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          session: this.$Cookies.get('session'),
          env: this.$Cookies.get('env'),
          platform: 'steam'
        })
      });
    },
    toggleMicroApp() {
      this.toggleMicroAppStatus(!this.isInMicroApp);
      if(!this.canShowAsideMenu) {
        this.toggleAsideMenu();
      }
      this.$router.push(`${this.baseUrl}/app-list`);
    },
    async getCartTotal() {
      const res = await this.getCartList();
      if (res) {
        // this.iconLis[0].badgeValue = this.cartTotal;
      }else{
        // this.iconLis[0].badgeValue = 0;
      }
    },
    judgeRoute() {
      // 根据路由做一些操作，如隐藏菜单
      if (ROUTE_LIS.includes(this.$route.path) && this.canShowAsideMenu) {
        this.toggleAsideMenu();
      }
    },
    goHome() {
      this.toggleAsideMenu();
      this.$router.push(`${this.baseUrl}/app-list`);
    },
    showShoppingCart() {
      if (!this.userInfo.name) {
        this.handleLogin();
        return;
      }
      if(!this.isCollapse) {
        this.toggleAsideMenu();
      }
      if(this.canShowAsideMenu) {
        this.toggleAsideMenu();
      }
      this.$router.push(`${this.baseUrl}/shopping-cart`);
    },
    showSeniorMember() {
      this.$router.push(`${this.baseUrl}/billing-plan`)
    },
    gotoProfile() {
      this.toggleAsideMenu();
      this.$router.push(`${this.baseUrl}/profile`);
    },
    gotoSkinSetting() {},
    async handleClick(type) {
      if(type === 'skin') {
        this.$message({
          message: '暂未开放',
          type: 'success'
        })
        return;
      }
      if(this.isInMicroApp){
        this.toggleMicroAppStatus(!this.isInMicroApp);
      }
      if(!this.canShowAsideMenu) {
        this.toggleAsideMenu();
      }
      switch (type) {
        case "profile":
          this.gotoProfile();
          break;
        case "skin":
          this.gotoSkinSetting();
          break;
        case "account-center":
          this.$router.push(`${this.baseUrl}/account-center`);
          break;
        case "logout": {
          const res = await this.logout();
          console.log(res, TOURIST_LIS, this.$route.path, 'platform_name', this.$Cookies.get('platform_name'));
          if (res) {
            if(!this.canShowAsideMenu) {
              this.toggleAsideMenu();
            }
            this.$Cookies.remove('platform_name')
            // 应该reload后，在加载微应用前做拦截判断
            // window.location.reload();
            if(TOURIST_LIS.includes(this.$route.path)){
              window.location.reload();
            }else{
              this.$router.push(`${this.baseUrl}/app-list`);
            }
            // this.iconLis[0].badgeValue = 0;
            // 只要退出登录就会跳到登录页
            // this.$router.push(`${this.baseUrl}/login`);
          }
          break;
        }
        case "reset": {
          this.$confirm('点击确定将会重置当前账号购买状态, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '我再想想'
          }).then(async () => {
            const res = await this.resetAccountStatus()
            if(res){
              window.location.reload();
            }
          }).catch(() => {
            
          });
          break;
        }
      }
    },
    handleLogin(text = "login"){
      this.toggleLoginRegisterName(text)
      this.$router.push(`${this.baseUrl}/login`);
    },
    toggleMenu() {
      this.toggleCollapse();
      if (!this.isCollapse) {
        setTimeout(() => {
          this.canShowTitle = !this.isCollapse;
        }, 0);
        return;
      } else {
        this.canShowTitle = !this.isCollapse;
      }
    },
    ...mapActions([
      "getCdnPath", 
      "getUserInfo", 
      "logout", 
      "getCartList", 
      "resetAccountStatus", 
      "getLis", 
      "getAclData",
    ]),
    ...mapMutations([
      "toggleAsideMenu",
      "toggleCollapse",
      "toggleMicroAppStatus",
      "toggleLoginRegisterName",
      "setMicroAppMenu",
      "saveCurrIntroName",
      "toggleCustomerService",
      "saveCurrHash",
    ])
  }
};
</script>

<style lang="less">
@import url("~@/assets/style/base.less");
</style>