/**
 * 配置编译环境和线上环境之间的切换
 *
 * baseUrl: 域名地址
 * routerMode: 路由模式
 * imgBaseUrl: 图片所在域名地址
 *
 */
let baseUrl, apiUrl, cdnUrl;
let isProd;
const imgBaseUrl = '';


if (process.env.NODE_ENV == 'production') {
  baseUrl = '/html';  // main的env.js设置为 baseUrl= '/html';  // 主应用里跳路由  后续要看是否有影响，暂时设置空
  apiUrl = '';
  cdnUrl = 'https://oss.eai9.com'
  isProd = true
} else {
  baseUrl = '';
  apiUrl = '/api';
  cdnUrl = 'https://oss.eai9.com'
  isProd = false
}

export {
  baseUrl,
  imgBaseUrl,
  apiUrl,
  cdnUrl,
  isProd,
}
