import { apiGet, apiPost } from '@common/service'

// const smsUrl = process.env.NODE_ENV === 'production' ? '/' : '/sms'

export default {
  logoutUrl: () => apiPost('/logout'),
  getUserInfoUrl: () => apiGet('/v1/uinfo'),
  getLisUrl: () => apiGet('/v1/acl/lis'),
  // 购物车
  postCartListUrl: data => apiPost('/v1/cart/lis', data),
  postCartAddUrl: ({acl_id, year}) => apiPost(`/v1/cart/add?acl_id=${acl_id}&year=${year}`),
  // 客服
  getChatLisUrl: last_id => apiGet(`/v1/chat/history?last_id=${last_id}`),
  postChatMsgUrl: data => apiPost('/v1/chat/send', data),
  getAclUrl: () => apiGet('/v1/admin/acl/lis'),
  resetAclUrl: () => apiPost('/v1/reset/acl'),
  // 获取服务器json
  cdnUrl: () => apiGet('/html/path/cdn.json')
}

