import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import { baseUrl } from '../service/env'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: '/app-list'
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由白名单
const whitelist = ['/login', '/404', '/', '/app-list'].map(v => baseUrl+v)

// 如果在权限路由表中，就不显示
router.beforeEach((to, from, next) => {
  console.log('beforeEach', to, from, next);
  if(!Cookies.get('platform_name') && !whitelist.includes(to.path)){
    if(['/system-introduction', '/billing-plan'].includes(to.path)) {
      return next({ path: baseUrl+'/login', replace: true })
    }
    return next({ path: baseUrl+'/app-list', replace: true })
  }
  return next();
})

export default router
