<template>
  <el-scrollbar class="" :style="{overflow: 'hidden', height: height}">
    <slot></slot>
  </el-scrollbar>
</template>

<script>
export default {
  props:{
    height: {
      type: String,
      default: '100%'
    }
  }
}
</script>

<style lang="less">
.el-scrollbar__view {
  height: 100vh;
}
.el-scrollbar{
  width: 100% !important;
}
.el-scrollbar__wrap{
  overflow-x: hidden !important;
  margin-right: -18px !important;
  margin-bottom: auto !important;
}
</style>