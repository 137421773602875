<template>
  <el-card class="customer-service-box" shadow="never" v-show="show">
    <div slot="header" class="clearfix">
      <h3>客服在线咨询</h3>
      <div class="pointer" style="float: right; padding: 3px 0" @click="handleClose">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="customer-service-body">
      <el-scrollbar class="custom-scrollbar" ref="ektScrollbar">
        <!-- <div class="customer-service-msg-item">
          <span>开始对话</span>
        </div> -->
        <div :class="['customer-service-msg-item']" v-for="(item, index) in chatLis" :key="item.id">
          <template v-if="index > 0 && getCurrTime(index)">
            <div class="chat-time">
              <span>{{getChatTime(item)}}</span>
            </div>
          </template>
          <template v-if="index === 0">
            <div class="chat-time">
              <span>{{item.ctime}}</span>
            </div>
          </template>
          <div :class="['customer-service-text', isNaN(parseInt(item.from)) ?'customer-service-user':'customer-service-gm']">
            <div class="customer-service-avatar" v-if="!isNaN(parseInt(item.from))">
              <img class="avatar" :src="serviceAvatar" alt="">
            </div>
            <div class="customer-service-bubble">
              <span v-html="item.msg.msg"></span>
            </div>
            <div class="customer-service-avatar" v-if="isNaN(parseInt(item.from))">
              <img class="avatar" :src="getAvatar" alt="">
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="customer-service-footer">
      <div class="customer-service-toolbar" v-if="show">
        <div>
          <el-popover
            placement="top"
            width="340"
            v-model="canShowEmojiPop"
            trigger="click">
            <div class="popover-emoji-list">
              <div 
                v-for="(item, index) in emojiLis" 
                :key="index" 
                :title="item.name" 
                @click="e => handleSelectEmoji(e, item)">
                <img class="emoji-item" :src="`${baseCdn}/${cdnPath[item.name]}`" style="width:24px;height:24px;padding:5px;" />
              </div>
            </div>
            <i class="aegoole-icon icon-icon1" slot="reference" title="表情"></i>
          </el-popover>
        </div>
        <!-- <div @click="handleUpload">
          <i class="aegoole-icon icon-a-zu29923" title="图片"></i>
          <input style="display:none;" type="file" ref="file">
        </div> -->
      </div>
      <div class="customer-service-textarea">
        <div 
          id="service-edit-textarea" 
          ref="content" 
          :contenteditable="true" 
          v-html="value"
          @click="e => locateCursor(e)"
          @keyup="handleKeyup"
          @keydown.ctrl.enter="lineFeed"
          @keydown.exact="handleKeydown">
        </div>
      </div>
      <div class="customer-service-func">
        <EktButton type="primary" :is-radius="true" :is-disabled="!canSubmit" @click="handleSend">发送</EktButton>
      </div>
    </div>
  </el-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EktButton from '@common/components/ekt-button'
import { EMOJI_LIS } from '@/constants'
import { cdnUrl } from '@/service/env'
import utils from '@/utils/util'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: '',
      emojiLis: EMOJI_LIS,
      serviceAvatar: require('../../assets/img/service.png'),
      last_id: 0,
      canShowEmojiPop:false,
      lastScrollHeight: 0,
      canSubmit:false,
      selection: null,
      range: null,
      interval: 20000,
      baseCdn: cdnUrl,
    }
  },
  components:{
    EktButton,
  },
  computed: {
    getAvatar(){
      return this.userInfo.avatar || this.cdnPath.defaultAvatar
    },
    getChatTime(){
      return function(item){
        // const d = new Date();
        return item.ctime;
      }
    },
    getCurrTime(){
      return function(index){
        let prev = this.chatLis.find((v, k) => k === index-1)
        let curr = this.chatLis[index]
        return new Date(curr.ctime).getTime() - new Date(prev.ctime).getTime() > 1000*60*60*24;
      }
    },
    ...mapState(['chatLis', 'userInfo', 'cdnPath'])
  },
  watch:{
    show: {
      handler(){
        // if(this.show){
          let timer = setInterval(() => {
            let el = this.$refs.ektScrollbar.wrap;
            if(el.scrollTop === 0 && this.chatLis.length > 0){
              el.scrollTop = el.scrollHeight;
              this.lastScrollHeight = el.scrollHeight;
              el.onscroll = () => {
              }
            }else{
              clearInterval(timer)
              timer = null;
            }
          }, this.interval)

          this.$once('hook:beforeDestroy', () => {
            clearInterval(timer)
          })
          
          this.$nextTick(() => {
            this.$refs.content.focus();
          })
        // }else{
        //   clearInterval(this.timer)
        //   this.timer = null;
        // }
      }
    }
  },
  mounted() {
    console.log('....',this.$refs.ektScrollbar);
    if(!this.$Cookies.get('platform_name')) return;
    // 调试输入时不开启
    const timer1 = setInterval(() => {
      this.getChatLisData();
    }, this.interval)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(timer1)
    })
  },
  methods: {
    handleKeydown(e){
      if(e.keyCode==13){
        e.preventDefault();//禁用回车的默认事件
        this.handleSend();
      }
    },
    handleKeyup(e){
      console.log('keyup...',e,this.$refs.content.innerHTML);
      let value = this.$refs.content.innerText;
      if(utils.checkIsAllEmpty(value)){
        this.canSubmit = true;
      }else{
        this.canSubmit = false;
      }
      this.locateCursor()
    },
    locateCursor(el){  // 定位光标
      console.log('locateCursor', el);
      this.selection = window.getSelection();  // 获取选定对象
      if(this.selection.getRangeAt && this.selection.rangeCount){  // 必须要点击过
        this.range = this.selection.getRangeAt(0);  // 获取Range对象（最后点击时的光标对象）
      }
    },
    lineFeed(e){
      //ctrl+enter换行
      console.log('line....',e);
      if(e.ctrlKey && e.keyCode === 13){
        // this.value = this.$refs.content.innerHTML;
        // console.log(this.value);
        // this.value += '<div><br></div>'
        // const el = document.createElement('div')
        // el.innerHTML = '<br>';
        this.$refs.content.focus();
        // 由于表情包是插入前面，不能这样用了。。
        // this.parseHtmlAtCaret('<br />')
        
      }else{
        this.handleSend();
      }
    },
    parseHtmlAtCaret(html){
      if(this.selection.getRangeAt && this.selection.rangeCount){ 
        this.range.deleteContents();

        const el = document.createElement('div')
        el.innerHTML = html;
        let frag = document.createDocumentFragment(), node, lastNode;
        while((node = el.firstChild)){
          lastNode = frag.appendChild(node);  // lastNode为表情包节点如：<b><img src="xx"/></b>
        }
        this.range.insertNode(frag);  // 在Range的起始位置插入节点(表情包)
        if(lastNode){
          this.range = this.range.cloneRange();  // 返回拥有和原 Range 相同端点的克隆 Range 对象
          // setStartAfter(referenceNode)
          // 设置范围相对于节点的起始位置。Range 起点的父节点将与 referenceNode 的父节点相同。
          this.range.setStartAfter(lastNode);   // 以其它节点为基准，设置 Range 的起点
          this.range.collapse(true);   // 向边界点折叠该 Range。true 折叠到 Range 的 start 节点。折叠后的 Range 为空，不包含任何内容。（即不拖拽选中任何文本）
          this.selection.removeAllRanges();
          this.selection.addRange(this.range)
        }
      }
    },
    handleSelectEmoji(e, item){
      console.log(e, item, this.value);
      this.$refs.content.focus();
      const emoji = `<img class="" src="${cdnUrl}/${this.cdnPath[item.name]}" style="width:24px;height:24px;vertical-align: bottom;" />`
      this.parseHtmlAtCaret(`${emoji}`);
      this.canShowEmojiPop = false;
    },
    async getChatLisData(){
      if(!this.$refs.ektScrollbar) return;
      let el = this.$refs.ektScrollbar.wrap;
      const res = await this.getChatLis(this.last_id);
      if(res){
        if(this.chatLis.length > 0){
          this.last_id = this.chatLis[this.chatLis.length-1].id
          // el.scrollTop = el.scrollHeight;
        }
        // 如果本身滚动条在上一次的高度，则直接赋值新的。否则，提示有新消息
        
        if(this.lastScrollHeight < el.scrollHeight){
          el.scrollTop = el.scrollHeight;
          this.lastScrollHeight = el.scrollHeight;
        }
      }
    },
    handleUpload(){
      this.$refs.file.click();
    },
    async handleSend(){
      console.log('send...', this.value,this.$refs.content.innerHTML);
      if(this.$refs.content.innerHTML === ''){
        alert('内容空');
        return;
      }
      const res = await this.sendMsg({
        msg: {
          msg: this.$refs.content.innerHTML,
          type: 'txt'
        },
        target_type: 'users'
      })
      console.log(res);
      if(res){
        this.$refs.content.innerHTML = '';
        this.getChatLisData();
        console.log('finish...', this.value,this.$refs.content.innerHTML);
      }
    },
    handleClose(){
      this.$emit('handle-close')
    },
    ...mapActions(['sendMsg', 'getChatLis'])
  },
}
</script>

<style lang="less">
@import url('./index.less');
.custom-scrollbar{
  overflow: hidden;
  height: 100%
}
</style>