<template>
  <div class="affix-wrapper" :style="getStyle">
    <div :class="getClass(item)" v-for="(item, index) in data" :key="index" @click="item.click">
      <el-badge :value="item.badgeValue" :hidden="!item.canShowBadge || !item.badgeValue" class="">
        <img v-if="item.src" :src="item.src" :alt="item.title" :title="item.title">
        <i v-else :class="[`aegoole-icon ${item.icon} theme-blue`]"></i>
      </el-badge>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    top: {
      type: String,
      default: '35%'
    },
    right: {
      type: String,
      default: '20px'
    },
    data: {
      type: Array,
      default: () => []
    },
    currSelect: {
      type: String,
      default: ''
    }
  },
  computed: {
    getStyle(){
      return {
        top: this.top,
        right: this.right,
      }
    },
    getClass(){
      return function(item){
        return `${item.type}-wrapper ${this.currSelect === item.title ? 'active' : ''}`
      }
    }
  },
}
</script>

<style lang="less">
@import url('./index.less');
</style>