<template>
  <div :class="['ekt-aside-wrapper', customClass]">
    <el-aside :width="width">
      <slot></slot>
    </el-aside>
  </div>
</template>

<script>
export default {
  props:{
    width:{
      type: String,
    },
    customClass:{
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.ekt-aside-wrapper{
  height: 100%;
  .el-aside{
    border-right: 2px solid #EAEDF3;
    overflow: hidden;
    height: 100%;
    background: #fff;
    /deep/ .el-scrollbar__bar.is-horizontal{  // 不显示侧栏底部横条
      display: none;
    }
  }
}
</style>